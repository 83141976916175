import request from "@/utils/request";

export function del(id) {
  return request({
    url: `/good/${id}`,
    method: "delete",
  });
}

export function reset(id) {
  return request({
    url: `/good/reset/${id}`,
    method: "put",
  });
}

export function adminSearch(data) {
  return request({
    url: "/good/oper/search/admin",
    method: "get",
    params: data,
  });
}

export function meSearch(data) {
  return request({
    url: "/good/oper/search/me",
    method: "get",
    params: data,
  });
}
